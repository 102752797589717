.jumbotron {
    background-image: url("https://rla-and-mortgage-broker314133607417.s3.amazonaws.com/3d-rendering-modern-dining-room-living-room-with-luxury-decor_29.jpg");
    display: block;
    background-size: cover;
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
}

.first-font {
    font-size: 57px;
    font-weight: 700;
    color: #ffffff;
}

.second-font {
    font-size: 21px;
    font-weight: 400;
    color: #ffffff;

}

.filter, .filters{
    margin-top: 40px;
    border-radius: 10px;
}

.input-border {
    border-right: 0.5px black thin;
    border-top: 0;
    border-radius: 0;
    border-bottom: 0;
    border-left: 0;
    height: 10px;
}


.input-border:focus {
    border-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) inset, 0 0 8px rgb(255, 255, 255);
  outline: 0 none;
}

.separator {
    color: #ffffff;
    font-weight: 900;
    display: flex;
  align-items: center;
  text-align: center;
  }
  
  .separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ffffff;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
  .overlay {
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }

@media screen and (max-width: 600px) {
  .jumbotron {
    /* background-image: url("https://rla-and-mortgage-broker314133607417.s3.amazonaws.com/3d-rendering-modern-dining-room-living-room-with-luxury-decor_29.jpg"); */
    background-size: cover;
    display: block;
    height: 950px;
    background-repeat: no-repeat;
    background-position: center;
}

.filter {
  width: 80%;
  
  
}

.input-border {
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  border-bottom: 0;
  border-left: 0;
  height: 10px;
}

.first-font {
  font-size: 47px;
  font-weight: 500;
  color: #ffffff;
}

.second-font {
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;

}

}