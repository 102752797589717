
.property:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,.2);
    border-radius: 0.5rem;

}


.property img {
        float: left;
        width:  100%;
        height: 200px;
        object-fit: cover;
        border-radius: 0.5rem;
        
}


  p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.top-right {
    position: absolute;
    top: 8px;
    right: 16px;
    background-color: rgb(12, 181, 91);
    padding: 4px;
    font-size: 11px;
    border-radius: 6px;
    font-weight: 500;
    color: white;
  }
  

.top-right-two {
    position: absolute;
    top: 34px;
    right: 16px;
    background-color: rgb(181, 12, 12);
    padding: 4px;
    font-size: 11px;
    border-radius: 6px;
    font-weight: 500;
    color: white;
  }
  
  .card_link {
    color: black;
    text-decoration: none;
  }

  .card_link:hover {
    color: black;
    text-decoration: none;
  }