.recommended .property {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    

   
}

.recommended .property:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: none; 
}

@media screen and (max-width: 600px) {
    .reduce{
        display: block;
        width: fit-content;
    }

  }

