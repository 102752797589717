.banks-title {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
}

.banks-text {
    font-size: 10px;
    font-weight: 700;
    text-align: center;
}

.banks-link {
    font-size: 13px;
    font-weight: 700;
    text-align: center;

}



.banks-btn {
    padding: 5px;
    width: 100%;
}

.bank-image {
    float: left;
    width:  100%;
    height: 100px;
    border-radius: 0.5rem;
    padding: 20px;
}

.bank-card {
    border-radius: 15px;
}