.acc {
    background-color: #ffffff;
    width: fit-content;
    border: 0;

}


.features {
    background-color: rgba(245, 245, 245, 0.466);

}