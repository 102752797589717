.buy {
    padding-left: 70px;
}
/* a.active{
    color: #198754;
    background-color: #d2ffea;
} */
@media screen and (max-width : 600px) {
    .buy {
        padding: 0;
    }
}