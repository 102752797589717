#propImage {
    background-size: cover;
    background-position: center;
    height: 40rem;
    border-radius: 10px;
 }


.finance-options {
    width: 100%;
    height: 5rem;
    background-color: rgb(241, 230, 225);
    
}
.finance-options img {
    height: 100%;
}

.footer-full {
    background-color: rgb(237, 237, 237);
}

.google-map {
    padding-bottom: 50%;
    position: relative;
}

.google-map iframe{
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    padding-bottom: 80px;
    padding-top: 20px;
}

.description {
    background-color: rgba(245, 245, 245, 0.466);

}

.modal-header {
    border: 0;
}

label, .modal-title {
    color: rgb(0, 0, 0);
}

@media screen and (max-width: 600px) {
    .price{
        display: flex;
        flex-direction: column;
    }

.google-map iframe{
    height: 400px ;    
    padding-bottom: 80px;
    padding-top: 20px;
}

  }

.disclaimer {
    background-color: rgb(245, 245, 192);
}