.footer-full {
    background-color: rgb(237, 237, 237);
}

#phone {
    padding: 10px;  
    margin: 0; 
    border: 1px solid #198754;
}

.download {
    width: 130px;
}

.phone-icon {
    padding: 13px; 
    margin: 0; 
    color: white;
}

@media screen and (max-width : 600px) {
    .footer-full {
        font-size: 13px;
    }    
    .download {
        width: 100px;
    }
}